<template>
  <div class="container">
    <div class="text-right">
      <v-btn :to="{ name: 'dbRevisions' }">Data Revisions</v-btn>
    </div>
    <div class="text-h6">
      This audit tool will check data integrity of the system.
    </div>
    <v-divider />
    <v-select
      label="Check inventories since this closing date"
      v-model="sinceSelectedClosing"
      :items="accountClosingList"
      item-value="id"
      item-text="label"
      return-object
      hide-details
    />

    <div v-if="sinceSelectedClosing" class="text-caption mt-2">
      Notes: {{ sinceSelectedClosing.notes }}
    </div>

    <div class="d-flex">
      <v-btn class="my-8 mx-auto" @click="startAudit" color="primary"
        >Start Audit</v-btn
      >
    </div>

    <v-divider />
    <v-progress-linear color="primary" indeterminate :active="loading" />

    <v-sheet :elevation="2" class="pa-5 mb-5">
      <div
        class="d-flex ma-2 justify-space-between"
        v-for="(audit, index) in auditList"
        :key="index"
      >
        <div>{{ index + 1 }}. {{ audit.description }}</div>
        <div>
          <span v-if="audit.status == 'checking'">
            <v-progress-circular indeterminate />
          </span>
          <span v-else>{{ audit.status }}</span>
        </div>
      </div>
    </v-sheet>
    <div class="font-weight-bold text-center ma-4" v-if="!loading && result">
      {{ result }}
    </div>
    <div ref="console" class="text-caption pa-5 console">
      {{ console }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  auditPurchaseStockTime,
  auditInputOutOfRange,
  auditOutputOutOfRange,
  auditOutputStockTimeMismatch,
  auditZeroInventoryCost,
  auditOuputNotStocked,
  auditPurchaseNotStocked,
  auditAdjustmentOverSpread,
  auditStockedPurchaseItemCostMismatch,
  auditInventoryItemDoubleSold,
  auditRemovedInventoryInUse,
  auditNegativeBalanceInventories,
  auditInventoryWithoutSource,
} from "@/services/dataAuditServices.js";

export default {
  data: () => {
    return {
      sinceSelectedClosing: null,
      totalClosingSize: null,
      accountClosingList: null,
      console: "",
      loading: false,
      result: "",
      auditList: [
        {
          description:
            "Audit purchase item and stocked inventory time matches.",
          status: "Pending",
          fun: auditPurchaseStockTime,
        },
        {
          description:
            "Audit process input time are within range of process start and end time.",
          status: "Pending",
          fun: auditInputOutOfRange,
        },
        {
          description:
            "Audit process output time are within range of process start and end time.",
          status: "Pending",
          fun: auditOutputOutOfRange,
        },
        {
          description:
            "Audit process output and inventory stock time mismatch.",
          status: "Pending",
          fun: auditOutputStockTimeMismatch,
        },
        {
          description: "Audit non-waste inventories with zero cost.",
          status: "Pending",
          fun: auditZeroInventoryCost,
        },
        {
          description: "Audit outputs in ended process that are not stocked.",
          status: "Pending",
          fun: auditOuputNotStocked,
        },
        {
          description: "Audit purchase items that are not stocked.",
          status: "Pending",
          fun: auditPurchaseNotStocked,
        },
        {
          description: "Audit spread over adjustment inventory.",
          status: "Pending",
          fun: auditAdjustmentOverSpread,
        },
        {
          description: "Audit stocked purchase item cost mismatch.",
          status: "Pending",
          fun: auditStockedPurchaseItemCostMismatch,
        },
        {
          description:
            "Audit inventory unit item double sold (packaged in multiple packing list).",
          status: "Pending",
          fun: auditInventoryItemDoubleSold,
        },
        {
          description: "Audit removed inventories that are still still in use.",
          status: "Pending",
          fun: auditRemovedInventoryInUse,
        },
        {
          description:
            "Audit inventories that were overused (inventory with a negative balance).",
          status: "Pending",
          fun: auditNegativeBalanceInventories,
        },
        {
          description:
            "Audit inventories without a source (purchase, process, or adjustment).",
          status: "Pending",
          fun: auditInventoryWithoutSource,
        },
        // {
        //   description: "Audit inventory availability status to be accurate.",
        //   status: "Pending",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters("accountClosing", [
      "accountClosingProcessed",
      "getAccountClosings",
    ]),
  },
  mounted() {
    this.loadAccountClosings();
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("accountClosing", [
      "deleteAccountClosing",
      "fetchAccountClosings",
    ]),

    loadAccountClosings() {
      const sortBy = "id";
      const sortDesc = true;
      const params = {
        page: 0,
        size: 100,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
      };
      this.fetchAccountClosings(params)
        .then((response) => {
          if (response) {
            this.totalClosingSize = response.totalElements;
            this.accountClosingList = response.content;
            if (this.accountClosingList) {
              this.accountClosingList.forEach((c) => {
                c.label = `${this.$options.filters.formatDateYear(
                  c.closingDate
                )} by ${c.adminUserName}`;
              });

              this.sinceSelectedClosing = this.accountClosingList[0];
            }
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    appendConsole(text) {
      this.console += "\n" + text;
    },
    async startAudit() {
      this.loading = true;
      this.console = "";
      this.result = "";

      let startDate = this.sinceSelectedClosing.closingDate;
      startDate = startDate.substring(0, 10);
      for (const audit of this.auditList) {
        if (audit.fun) {
          audit.status = "Checking";
          this.appendConsole("\n-\n");
          this.appendConsole(`Running: ${audit.description}`);
          await audit.fun(startDate).then((resp) => {
            const result = resp.data;
            audit.status = result.status;
            const failed = result.failedAuditData;
            if (failed && failed.length > 0) {
              this.appendConsole("Found the following data mismatched:\n ");
              failed.forEach((obj) => {
                Object.keys(obj).forEach(
                  (k) => obj[k] == null && delete obj[k]
                );
              });
              failed.forEach((f) => {
                this.appendConsole(f.reason);
              });
            }

            this.appendConsole(
              `\nAudit completed and found ${
                failed.length
              } records with status ${audit.status} in ${
                result.runTimeInMilliSeconds / 1000
              } seconds`
            );
          });
        }
      }

      this.scrollToConsole();

      // await this.findAllUnavailableInventories();

      this.loading = false;
    },
    async findAllUnavailableInventories() {
      this.appendConsole("Looking up all unavailable inventories");
      let startDate = this.sinceSelectedClosing.closingDate;
      startDate = startDate.substring(0, 10);
      return await this.$axios
        .get("/inventory/unavailable", { params: { start_date: startDate } })
        .then((result) => {
          if (result.data) {
            const inventories = result.data;
            this.appendConsole(
              `found ${
                inventories.length
              } inventories marked as unavailable since ${this.$options.filters.formatDateYear(
                this.sinceSelectedClosing.closingDate
              )}`
            );

            const failedInventories = [];
            inventories.forEach((i) => {
              const passed = this.checkInventory(i);
              if (!passed) {
                failedInventories.push(i);
              }
            });

            if (failedInventories.length > 0) {
              this.result = `Found ${failedInventories.length} failing audit check`;
            } else {
              this.result = "Great! no irregularity found";
            }
          }
        });
    },
    checkInventory(inventory) {
      const passed = inventory.quantityAvailable > 0 ? false : true;
      this.appendConsole(
        `checking inventory ${inventory.productName} (${
          inventory.id
        }) - ${this.$options.filters.formatNumber(
          inventory.quantityAvailable
        )} of ${this.$options.filters.formatNumber(
          inventory.quantity
        )} remaining (${passed ? "passed" : "failed"})`
      );

      return passed;
    },
    scrollToConsole() {
      const el = this.$refs.console;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.console {
  border: 1px solid #ccc;
  max-height: 600px;
  overflow: auto;
  white-space: pre-line;
}
</style>
